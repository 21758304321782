import Vue from 'vue';
import { Context } from '@nuxt/types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';

import { LandingPageTypes } from './types';
import API from '~/modules/landingPageTypes/api';
import { RootState } from '~/store';

export const state = () => ({
    list: [] as LandingPageTypes[],
    errors: {},
    isLoading: false as boolean
});

export type LandingPagesTypesModuleState = ReturnType<typeof state>;

export const MUTATIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    RESET_ERRORS: 'RESET_ERRORS',
    SET_LIST: 'SET_LIST',
    RESET_LIST: 'RESET_LIST',
    SET_ERRORS: 'SET_ERRORS',
};

export default ({ store }: Context) => {
    const getters: GetterTree<LandingPagesTypesModuleState, RootState> = {
        list: state => state.list,
        getByIdentifier: state => (identifier: string) => {
            return state.list.find(type => type.identifier === identifier);
        },
    };

    const mutations: MutationTree<LandingPagesTypesModuleState> = {
        [MUTATIONS.START_LOADING]: (state) => {
            Vue.set(state, 'isLoading', true);
        },
        [MUTATIONS.STOP_LOADING]: (state) => {
            Vue.set(state, 'isLoading', false);
        },
        [MUTATIONS.SET_LIST]: (state, list) => {
            Vue.set(state, 'list', list);
        },
        [MUTATIONS.RESET_LIST]: (state) => {
            Vue.set(state, 'list', []);
        },
        [MUTATIONS.SET_ERRORS]: (state, errors) => {
            state.errors = errors;
        },
        [MUTATIONS.RESET_ERRORS]: (state) => {
            state.errors = {};
        },
    };

    const actions: ActionTree<LandingPagesTypesModuleState, RootState> = {
        fetchAll: async ({ commit, dispatch }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.fetchAll();
                commit('SET_LIST', data.data);
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
    };

    store.registerModule('landingPageTypes', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
