// Reference: https://typescript.nuxtjs.org/cookbook/plugins/
import { Plugin } from '@nuxt/types';
import { StorageInterface } from '~/modules/storage/types';
import Storage from '~/modules/storage/storage';

declare module 'vue/types/vue' {
    interface Vue {
        $storage: StorageInterface;
    }
}

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $storage: StorageInterface;
    }
    interface Context {
        $storage: StorageInterface;
    }
}

declare module 'vuex/types/index' {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    interface Store<S> {
        $storage: StorageInterface;
    }
}

const storagePlugin: Plugin = (_, inject) => {
    inject('storage', Storage);
};

export default storagePlugin;
