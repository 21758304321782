/**
 * HTTP request promises to the API endpoint for landing pages types.
 *
 * TODO: Move this file to TypeScript.
 */
const ENDPOINT = '/landingpage-types';

export default {
    fetchAll: params =>
        global.$nuxt.$axios({
            method: 'get',
            url: ENDPOINT,
            params
        }),
};
