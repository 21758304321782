'use strict';

import { GetterTree, ActionTree, MutationTree } from 'vuex/types/index';
import { Context } from '@nuxt/types';
import Vue from 'vue';

import { Store as StoreInterface } from './types';
import { RootState } from '~/store';

const API_ENDPOINT = '/stores';

export const state = () => ({
    list: [] as StoreInterface[],
    errors: {},
    selected: null as number | null,
    isLoading: false as boolean
});

export type StoresModuleState = ReturnType<typeof state>;

export const MUTATIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    SET_LIST: 'SET_LIST',
    RESET_LIST: 'RESET_LIST',
    SET_ERRORS: 'SET_ERRORS',
    RESET_ERRORS: 'RESET_ERRORS',
    SELECT: 'SELECT',
    UNSELECT: 'UNSELECT',
    ADD_OR_UPDATE: 'ADD_OR_UPDATE'
};

export default ({ store, app }: Context) => {
    const getters: GetterTree<StoresModuleState, RootState> = {
        activeStores: state => state.list.filter(store => store.activated_at != null),
        byId: state => (id: number) =>
            state.list.find(store => store.id === id),
        selected: state =>
            state.list.find(item => String(item.id) === String(state.selected))
    };

    const mutations: MutationTree<StoresModuleState> = {
        [MUTATIONS.START_LOADING]: (state) => {
            Vue.set(state, 'isLoading', true);
        },
        [MUTATIONS.STOP_LOADING]: (state) => {
            Vue.set(state, 'isLoading', false);
        },
        [MUTATIONS.SET_LIST]: (state, list) => {
            Vue.set(state, 'list', list);
        },
        [MUTATIONS.RESET_LIST]: (state) => {
            Vue.set(state, 'list', []);
        },
        [MUTATIONS.SET_ERRORS]: (state, errors) => {
            state.errors = errors;
        },
        [MUTATIONS.RESET_ERRORS]: (state) => {
            state.errors = {};
        },
        [MUTATIONS.SELECT]: (state, id) => {
            Vue.set(state, 'selected', id);
        },
        [MUTATIONS.UNSELECT]: (state) => {
            Vue.set(state, 'selected', null);
        },
        [MUTATIONS.ADD_OR_UPDATE]: (state, item) => {
            const list = [...state.list];
            const index = list.findIndex(e => String(e.id) === String(item.id));

            if (index !== -1) {
                list[index] = item;
            } else {
                list.push(item);
            }

            Vue.set(state, 'list', list);
        }
    };

    const actions: ActionTree<StoresModuleState, RootState> = {
        select: async (
            { commit, dispatch, getters },
            { id, refresh = false }
        ) => {
            // Do this to trigger the watcher in the _id.vue details page
            commit(MUTATIONS.UNSELECT);
            commit(MUTATIONS.SELECT, id);

            if (refresh || !getters.selected) {
                return await dispatch('get', id);
            } else {
                return getters.selected;
            }
        },
        apiError: ({ commit }, error) => {
            if (error && error.response && error.response.data) {
                if (error.response.data.message) {
                    app.$toast.error(error.response.data.message);
                }
                if (error.response.data.errors) {
                    commit(MUTATIONS.SET_ERRORS, error.response.data.errors);
                }
            }
        },
        list: async ({ commit, dispatch }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await app.$axios.get(API_ENDPOINT);
                commit('SET_LIST', data.data);
                return data.data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        get: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await app.$axios.get(`${API_ENDPOINT}/${id}`);
                commit(MUTATIONS.ADD_OR_UPDATE, data.data);
                return data.data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        update: async ({ commit }, { id, deploymentCredentials, activate }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            const response = await app.$axios.put(`${API_ENDPOINT}/${id}`, {
                deployment_credentials: deploymentCredentials,
                activate
            });
            commit(MUTATIONS.ADD_OR_UPDATE, response.data.data);
            commit(MUTATIONS.STOP_LOADING);
            return response;
        },
        listWithLandingPageRelation: async ({ commit, dispatch }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await app.$axios.get(API_ENDPOINT, {
                    params: { hasLandingPages: true }
                });
                return data.data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        }
    };

    store.registerModule('stores', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
