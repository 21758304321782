import Vue from 'vue';

Vue.directive('can', (_, binding, vnode) => {
    vnode.context.$nextTick(() => {
        if (binding.value === true || binding.value === false) {
            if (binding.value === false) {
                if (vnode.elm.parentElement) {
                    vnode.elm.parentElement.removeChild(vnode.elm);
                }
            }
        } else if (binding.value.length >= 1) {
            if (
                vnode.context.$store.state.auth.user.permission.filter(
                    permission => binding.value.includes(permission.name)
                ).length === 0
            ) {
                if (vnode.elm.parentElement) {
                    vnode.elm.parentElement.removeChild(vnode.elm);
                }
            }
        }
    });
});

Vue.directive('is', (_, binding, vnode) => {
    vnode.context.$nextTick(() => {
        if (binding.value.length >= 1) {
            if (
                !binding.value.includes(
                    vnode.context.$store.state.auth.user.role.name
                )
            ) {
                if (vnode.elm.parentElement) {
                    vnode.elm.parentElement.removeChild(vnode.elm);
                }
            }
        }
    });
});
