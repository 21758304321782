/**
 * HTTP request promises to the API endpoint for landing pages.
 * Those requests are most often used in context of the store
 * in order to process and than offer the data.
 */
const ENDPOINT = '/landingpages';

export const fetchAllLandingPages = (params = {}) =>
    window.$nuxt.$axios.get(ENDPOINT, { params });

export const fetchLandingPage = (id: any) =>
    window.$nuxt.$axios.get(`${ENDPOINT}/${id}`);

export const createLandingPage = ({ data }: { data: object }) =>
    window.$nuxt.$axios.post(ENDPOINT, data);

export const copyLandingPage = (data: { id: any, title: string }) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${data.id}/copy`, data);

export const deployLandingPage = (id: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${id}/deploy`);

export const updateLandingPage = (id: any, data: any) =>
    window.$nuxt.$axios.put(`${ENDPOINT}/${id}`, data);

export const uploadLandingPageHtml = (id: any, data: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${id}/code/store`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

export const uploadLandingPageImage = (id: any, data: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${id}/asset/store`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

export const deleteLandingPage = (id: any) =>
    window.$nuxt.$axios.delete(`${ENDPOINT}/${id}`);

export const deactivateLandingPage = (id: any, redirectUrl: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${id}/deactivate`, {
        id,
        redirect_url: redirectUrl
    });

export const reactivateLandingPage = (id: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${id}/reactivate`, {
        landingpageId: id
    });

export const fetchLandingPageDeployments = (id: any) =>
    window.$nuxt.$axios.get(`${ENDPOINT}/${id}/deployments`);

export const fetchLandingPageRevision = (lpId: any, revId: any) =>
    window.$nuxt.$axios.get(`${ENDPOINT}/${lpId}/revisions/${revId}`);

export const fetchLandingPageRevisions = (id: any) =>
    window.$nuxt.$axios.get(`${ENDPOINT}/${id}/revisions`);

export const activateLandingPageRevision = (lpId: any, revId: any) =>
    window.$nuxt.$axios.post(`${ENDPOINT}/${lpId}/revisions/${revId}/activate`);

export default {
    fetchAll: fetchAllLandingPages,
    fetch: fetchLandingPage,
    create: createLandingPage,
    update: updateLandingPage,
    delete: deleteLandingPage,
    deactivate: deactivateLandingPage,
    reactivate: reactivateLandingPage,
    copy: copyLandingPage,
    deploy: deployLandingPage,
    uploadHtml: uploadLandingPageHtml,
    uploadImage: uploadLandingPageImage,
    fetchDeployments: fetchLandingPageDeployments,
    fetchRevision: fetchLandingPageRevision,
    fetchRevisions: fetchLandingPageRevisions,
    activateRevision: activateLandingPageRevision
};
