'use strict';

import { NewCredential, Credential } from './types';

const ENDPOINT = '/deployment_credentials';

export const fetchAllCredentials = (args = {}) =>
    window.$nuxt.$axios.get(ENDPOINT, args);

export const createCredential = (payload: NewCredential) =>
    window.$nuxt.$axios.post(ENDPOINT, payload);

export const fetchCredential = (id: number | string) =>
    window.$nuxt.$axios.get(`${ENDPOINT}/${id}`);

export const updateCredential = (id: number | string, payload: Credential) =>
    window.$nuxt.$axios.patch(`${ENDPOINT}/${id}`, payload);

export const deleteCredential = (id: number | string) =>
    window.$nuxt.$axios.delete(`${ENDPOINT}/${id}`);

export const activateCredential = (id: number | string) =>
    window.$nuxt.$axios.patch(`${ENDPOINT}/${id}/activate`);

export const deactivateCredential = (id: number | string) =>
    window.$nuxt.$axios.patch(`${ENDPOINT}/${id}/deactivate`);

export default {
    list: fetchAllCredentials,
    show: fetchCredential,
    create: createCredential,
    update: updateCredential,
    delete: deleteCredential,
    activate: activateCredential,
    deactivate: deactivateCredential
};
