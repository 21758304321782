'use strict';
import { GetterTree } from 'vuex/types/index';
import { RootState } from '~/store';
import { CredentialsModuleState } from '~/modules/credentials/store/state';

export const getters: GetterTree<CredentialsModuleState, RootState> = {
    apiParams: state => ({
        itemsPerPage: state.options.itemsPerPage,
        sortDesc: state.options.sortDesc,
        sortBy: state.options.sortBy,
        page: state.options.page
    }),
    byId: state => (id: number | string) =>
        state.items.find(element => element.id === +id),
    serverItemsLength: state => state.serverItemsLength,
    isLoading: state => state.loading,
    options: state => state.options,
    search: state => state.search,
    items: state => state.items
};

export default getters;
