
/**
 * This layout purely exists, because there is no apparent way to omit
 * a layout when creating a page. It is used in such cases where
 * a layout is needed that is not tied to the nuxt js logic
 * of combining pages with layouts.
 *
 * To learn more, see the readme in the `layout` directory.
 * Additionally, the necessity of this structure becomes
 * apparent when reading the page components that
 * use this layout.
 */
export default {
  name: 'BlankLayout',
};
