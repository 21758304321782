import Vue from 'vue';
import { MutationTree } from 'vuex/types/index';

import { UsersModuleState } from '~/modules/users/store/state';

export const MUTATIONS = {
    REMOVE: 'REMOVE',
    SET_LIST: 'SET_LIST',
    SET_TOTAL: 'SET_TOTAL',
    SET_ROLES: 'SET_ROLES',
    STOP_LOADING: 'STOP_LOADING',
    START_LOADING: 'START_LOADING',
    ADD_OR_UPDATE: 'ADD_OR_UPDATE',
    SET_TABLE_OPTIONS: 'SET_TABLE_OPTIONS'
};

export const mutations: MutationTree<UsersModuleState> = {
    [MUTATIONS.REMOVE]: (state, id) => {
        const list = [...state.list].filter(item => item.id !== id);
        Vue.set(state, 'list', list);
    },
    [MUTATIONS.SET_LIST]: (state, list) => {
        Vue.set(state, 'list', list);
    },
    [MUTATIONS.SET_TOTAL]: (state, total) => {
        Vue.set(state, 'total', total);
    },
    [MUTATIONS.SET_ROLES]: (state, roles) => {
        Vue.set(state, 'roles', roles);
    },
    [MUTATIONS.STOP_LOADING]: (state) => {
        if (state.loading > 0) {
            state.loading -= 1;
        }
    },
    [MUTATIONS.START_LOADING]: (state) => {
        state.loading += 1;
    },
    [MUTATIONS.ADD_OR_UPDATE]: (state, item) => {
        const list = [...state.list];
        const index = list.findIndex(e => String(e.id) === String(item.id));

        if (index !== -1) {
            list[index] = item;
        } else {
            list.push(item);
        }

        Vue.set(state, 'list', list);
    },
    [MUTATIONS.SET_TABLE_OPTIONS]: (state, options) => {
        Vue.set(state, 'tableOptions', options);
    }
};

export default mutations;
