'use strict';

import Vue from 'vue';
import { MutationTree } from 'vuex/types/index';

import { CredentialsModuleState } from './state';
import { Credential } from '~/modules/credentials/types';

export const mutations: MutationTree<CredentialsModuleState> = {
    clearItems: (state): void => {
        Vue.set(state, 'items', []);
    },
    setLoading: (state, isLoading: boolean): void => {
        state.loading = isLoading;
    },
    upsertItem: (state, credential: Credential): void => {
        const items = [...state.items];
        const index = items.findIndex(element => element.id === credential.id);
        if (index !== -1) {
            items[index] = credential;
        } else {
            items.push(credential);
        }
        Vue.set(state, 'items', items);
    },
    updateOption: (state, { key, value }): void => {
        Vue.set(state.options, key, value);
    },
    setServerItemsLength: (state, value: number): void => {
        state.serverItemsLength = value;
    }
};

export default mutations;
