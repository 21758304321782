'use strict';

import { ActionTree } from 'vuex/types/index';
import { Context } from '@nuxt/types';
import { RootState } from '~/store';
import {
    deactivateCredential,
    fetchAllCredentials,
    activateCredential,
    createCredential,
    deleteCredential,
    updateCredential,
    fetchCredential
} from '~/modules/credentials/api';
import { NewCredential, Credential } from '~/modules/credentials/types';
import {
    CredentialsModuleState,
    state
} from '~/modules/credentials/store/state';
import { mutations } from '~/modules/credentials/store/mutations';
import { getters } from '~/modules/credentials/store/getters';

export default ({ store }: Context) => {
    const actions: ActionTree<CredentialsModuleState, RootState> = {
        fetchCredentials: async ({ commit, getters }) => {
            commit('setLoading', true);
            try {
                const { data } = await fetchAllCredentials({
                    params: getters.apiParams
                });
                commit('clearItems');
                commit('setServerItemsLength', data.meta.total);
                data.data.forEach((element: Credential) =>
                    commit('upsertItem', element)
                );
            } finally {
                commit('setLoading', false);
            }
        },
        fetchCredential: async ({ commit }, id: number | string) => {
            commit('setLoading', true);
            try {
                const { data } = await fetchCredential(id);
                commit('upsertItem', data.data as Credential);
            } finally {
                commit('setLoading', false);
            }
        },
        createCredential: async ({ commit }, credential: NewCredential) => {
            commit('setLoading', true);
            try {
                const { data } = await createCredential(credential);
                commit('upsertItem', data.data as Credential);
                return data.message;
            } finally {
                commit('setLoading', false);
            }
        },
        deleteCredential: async ({ commit }, id: number | string) => {
            commit('setLoading', true);
            try {
                const { data } = await deleteCredential(id);
                return data.message;
            } finally {
                commit('setLoading', false);
            }
        },
        updateCredentialLocally: ({ commit }, credential: Credential) => {
            commit('upsertItem', credential);
        },
        updateCredential: async ({ commit }, credential: Credential) => {
            commit('setLoading', true);
            try {
                const { data } = await updateCredential(
                    credential.id,
                    credential
                );
                commit('upsertItem', data.data as Credential);
                return data.message;
            } finally {
                commit('setLoading', false);
            }
        },
        activateCredential: async (
            { commit, getters },
            id: number | string
        ) => {
            commit('setLoading', true);
            try {
                const { data } = await activateCredential(id);
                commit('upsertItem', {
                    ...getters.byId(id),
                    deactivated_at: data.data.deactivated_at
                });
                return data.message;
            } finally {
                commit('setLoading', false);
            }
        },
        deactivateCredential: async (
            { commit, getters },
            id: number | string
        ) => {
            commit('setLoading', true);
            try {
                const { data } = await deactivateCredential(id);
                commit('upsertItem', {
                    ...getters.byId(id),
                    deactivated_at: data.data.deactivated_at
                });
                return data.message;
            } finally {
                commit('setLoading', false);
            }
        },
        updateOptions: ({ commit }, options) => {
            for (const key in options) {
                commit('updateOption', {
                    key,
                    value: options[key]
                });
            }
        }
    };

    store.registerModule('credentials', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
