const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isSuperAdmin'] = require('../middleware/isSuperAdmin.js')
middleware['isSuperAdmin'] = middleware['isSuperAdmin'].default || middleware['isSuperAdmin']

export default middleware
