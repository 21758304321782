
export default {
  data () {
    return {
      items: [
        {
          title: 'user.usermanagement',
          icon: 'mdi-account-multiple',
          to: '/users',
          dataTest: 'users',
          roles: [this.$role.admin, this.$role.super_admin]
        },
        {
          title: 'tenant.tenantmanagement',
          icon: 'mdi-view-grid',
          to: '/tenants',
          dataTest: 'tenants',
          roles: [this.$role.super_admin]
        },
        {
          title: 'store.management',
          icon: 'mdi-domain',
          to: '/stores',
          dataTest: 'stores',
          roles: [this.$role.admin, this.$role.super_admin]
        },
        {
          title: 'credentials.management',
          icon: 'mdi-bullseye-arrow',
          to: '/credentials',
          dataTest: 'credentials',
          roles: [this.$role.admin, this.$role.super_admin]
        }
      ]
    };
  }
};
