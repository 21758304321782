import { GetterTree } from 'vuex/types/index';

import { RootState } from '~/store';

import { UsersModuleState } from '~/modules/users/store/state';

export const getters: GetterTree<UsersModuleState, RootState> = {
    isLoading: state => !!state.loading,
    getById: state => (id: number) => state.list.find(item => item.id === id)
};

export default getters;
