import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
import has from 'lodash/has';

const initialState = () => ({
    allIds: [],
    byId: {},
    created: null,
    deleted: null,
    errors: {},
    isLoading: false,
    resetList: false,
    totalItems: 0,
    pageSize: 10,
    currentPage: 1,
    updated: null,
    cached: false,
    unsaved: []
});

export const ACTIONS = {
    ADD: 'ADD',
    ADD_UNSAVED: 'ADD_UNSAVED',
    REMOVE_UNSAVED: 'REMOVE_UNSAVED',
    RESET_CREATE: 'RESET_CREATE',
    RESET_DELETE: 'RESET_DELETE',
    RESET_LIST: 'RESET_LIST',
    RESET_SHOW: 'RESET_SHOW',
    RESET_UPDATE: 'RESET_UPDATE',
    RESET_ERRORS: 'RESET_ERRORS',
    SET_CREATED: 'SET_CREATED',
    SET_DELETED: 'SET_DELETED',
    SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_UPDATED: 'SET_UPDATED',
    SET_ERRORS: 'SET_ERRORS',
    SET_CACHED: 'SET_CACHED',
    TOGGLE_LOADING: 'TOGGLE_LOADING'
};

// @todo remove toasts from existing catch places
export const handleError = (commit, error) => {
    commit(ACTIONS.TOGGLE_LOADING);

    if (!error.response) {
        global.$nuxt.$toast.error(error);
    }

    if (!has(error, 'response.data')) {
        global.$nuxt.$toast.error(
            global.$nuxt.$t('general.unexpected_error_exception').toString()
        );
        return Promise.reject(error);
    }
    if (typeof error.response.data === 'string') {
        global.$nuxt.$toast.error(error.response.data);
        return Promise.reject(error);
    }

    if (error.response.data.errors) {
        commit(ACTIONS.SET_ERRORS, error.response.data.errors);
    }

    if (error.response.data.message) {
        let message = error.response.data.message;
        if (error.response.data.message.search('No query result for model')) {
            message = 'No data found';
        }

        if (error.response.data.toast === false) {
            global.$nuxt.$toast.error(message);
        }
    }

    return Promise.reject(error);
};

export default function makeCrudModule ({
    service,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dateConvert,
    useCache = false
} = {}) {
    return {
        state: initialState,

        getters: {
            getField,
            find: state => (id) => {
                return state.byId[id];
            },
            isUnsaved: state => (id) => {
                return state.unsaved.includes(id);
            },
            list: (state, getters) => {
                return state.allIds.map(id => getters.find(id));
            }
        },

        mutations: {
            updateField,
            [ACTIONS.TOGGLE_LOADING]: (state) => {
                state.isLoading = !state.isLoading;
            },
            [ACTIONS.ADD]: (state, item) => {
                Vue.set(state.byId, item.id, item);
                state.isLoading = false;
                if (state.allIds.includes(item.id)) {
                    return;
                }
                state.allIds.push(item.id);
            },
            [ACTIONS.ADD_UNSAVED]: (state, item) => {
                if (state.unsaved.includes(item.id)) {
                    return;
                }
                state.unsaved.push(item.id);
            },
            [ACTIONS.REMOVE_UNSAVED]: (state, item) => {
                if (!state.unsaved.includes(item.id)) {
                    return;
                }
                state.unsaved = state.unsaved.filter(id => id !== item.id);
            },
            [ACTIONS.SET_CREATED]: (state, created) => {
                state.created = created;
            },
            [ACTIONS.SET_DELETED]: (state, deleted) => {
                if (!state.allIds.includes(deleted.id)) {
                    return;
                }
                state.deleted = deleted;
                state.allIds = state.allIds.filter(id => id !== deleted.id);
                delete state.byId[deleted.id];
            },
            [ACTIONS.SET_TOTAL_ITEMS]: (state, totalItems) => {
                if (state.totalItems !== totalItems) {
                    state.totalItems = totalItems;
                }
            },
            [ACTIONS.SET_CURRENT_PAGE]: (state, page) => {
                state.currentPage = page;
            },
            [ACTIONS.SET_PAGE_SIZE]: (state, pageSize) => {
                state.pageSize = pageSize;
            },
            [ACTIONS.RESET_LIST]: (state) => {
                state.allIds = [];
                state.byId = {};
                state.isLoading = false;
                state.resetList = false;
                state.cached = false;
                state.unsaved = [];
            },
            [ACTIONS.RESET_CREATE]: (state) => {
                state.isLoading = false;
                state.created = null;
            },
            [ACTIONS.RESET_DELETE]: (state) => {
                state.isLoading = false;
                state.deleted = null;
            },
            [ACTIONS.RESET_SHOW]: (state) => {
                state.isLoading = false;
            },
            [ACTIONS.RESET_UPDATE]: (state) => {
                state.isLoading = false;
                state.updated = null;
            },
            [ACTIONS.SET_UPDATED]: (state, updated) => {
                state.byId[updated.id] = updated;
                state.updated = updated;
            },
            [ACTIONS.SET_ERRORS]: (state, errors) => {
                state.errors = errors;
            },
            [ACTIONS.RESET_ERRORS]: (state) => {
                state.errors = {};
            },
            [ACTIONS.SET_CACHED]: (state, value) => {
                state.cached = value;
            },
            reset (state) {
                // acquire initial state
                const s = initialState();
                Object.keys(s).forEach((key) => {
                    state[key] = s[key];
                });
            }
        },

        actions: {
            addLocal ({ commit }, values) {
                commit(ACTIONS.ADD, values);
                commit(ACTIONS.ADD_UNSAVED, values);
            },
            async create ({ commit }, values) {
                if (!service) {
                    throw new Error('No service specified!');
                }

                commit(ACTIONS.TOGGLE_LOADING);
                commit(ACTIONS.RESET_ERRORS);
                commit(ACTIONS.REMOVE_UNSAVED, values);

                try {
                    const { data } = await service.create(values);
                    commit(ACTIONS.TOGGLE_LOADING);
                    commit(ACTIONS.ADD, data);
                    commit(ACTIONS.SET_CREATED, data);
                    return data;
                } catch (error) {
                    handleError(commit, error);
                    throw error;
                }
            },
            delete ({ commit }, item) {
                if (!service) {
                    throw new Error('No service specified!');
                }

                commit(ACTIONS.TOGGLE_LOADING);
                commit(ACTIONS.RESET_ERRORS);

                return service
                    .delete(item)
                    .then(() => {
                        commit(ACTIONS.TOGGLE_LOADING);
                        commit(ACTIONS.SET_DELETED, item);
                        commit(ACTIONS.REMOVE_UNSAVED, item);
                    })
                    .catch(e => handleError(commit, e));
            },
            deleteLocal ({ commit }, item) {
                commit(ACTIONS.SET_DELETED, item);
                commit(ACTIONS.ADD_UNSAVED, item);
            },
            fetchAll ({ commit, state }, params) {
                if (!service) {
                    throw new Error('No service specified!');
                }

                commit(ACTIONS.TOGGLE_LOADING);
                commit(ACTIONS.RESET_ERRORS);

                if (has(params, 'page') && params.page === state.currentPage) {
                    state.cached = false;
                }

                /**
                 * There are cases where we need to force refreshing the list,
                 * e.g. when an item is deleted softly in the backend.
                 */
                if (has(params, 'noCache') && params.noCache) {
                    state.cached = false;
                }

                if (has(params, 'itemsPerPage') && params.itemsPerPage === -1) {
                    params.itemsPerPage = state.totalItems;
                }

                if (state.cached) {
                    commit(ACTIONS.TOGGLE_LOADING);
                    return;
                }

                return service
                    .findAll(params)
                    .then((retrieved) => {
                        commit(ACTIONS.TOGGLE_LOADING);
                        const data = retrieved.data.data;

                        commit(ACTIONS.SET_TOTAL_ITEMS, retrieved.data.meta.total);
                        commit(ACTIONS.SET_PAGE_SIZE, retrieved.data.meta.per_page);
                        commit(
                            ACTIONS.SET_CURRENT_PAGE,
                            retrieved.data.meta.current_page
                        );

                        commit(ACTIONS.RESET_LIST);

                        data.forEach((item) => {
                            commit(ACTIONS.ADD, item);
                        });

                        if (useCache) {
                            commit(ACTIONS.SET_CACHED, true);
                        }
                    })
                    .catch(e => handleError(commit, e));
            },
            // @todo map date fields like the find action
            load: ({ commit }, id) => {
                if (!service) {
                    throw new Error('No service specified!');
                }

                commit(ACTIONS.TOGGLE_LOADING);
                commit(ACTIONS.RESET_ERRORS);

                return service
                    .find(id)
                    .then((item) => {
                        commit(ACTIONS.TOGGLE_LOADING);
                        commit(ACTIONS.ADD, item.data);
                        commit(ACTIONS.REMOVE_UNSAVED, item.data);
                    })
                    .catch(e => handleError(commit, e));
            },
            resetCreate: ({ commit }) => {
                commit(ACTIONS.RESET_CREATE);
            },
            resetDelete: ({ commit }) => {
                commit(ACTIONS.RESET_DELETE);
            },
            resetShow: ({ commit }) => {
                commit(ACTIONS.RESET_SHOW);
            },
            resetUpdate: ({ commit }) => {
                commit(ACTIONS.RESET_UPDATE);
            },
            resetErrors: ({ commit }) => {
                commit(ACTIONS.RESET_ERRORS);
            },
            resetList: ({ commit }) => {
                commit(ACTIONS.RESET_LIST);
            },
            resetCaching: ({ commit }) => {
                commit(ACTIONS.SET_CACHED, false);
            },
            localUpdate: ({ commit }, item) => {
                commit(ACTIONS.SET_UPDATED, item);
                commit(ACTIONS.ADD_UNSAVED, item);
            },
            resetUnsaved: ({ commit }, item) => {
                commit(ACTIONS.REMOVE_UNSAVED, item);
            },
            update: async ({ commit }, item) => {
                commit(ACTIONS.TOGGLE_LOADING);
                commit(ACTIONS.RESET_ERRORS);

                try {
                    const { data } = await service.update(item);
                    commit(ACTIONS.SET_UPDATED, item);
                    commit(ACTIONS.REMOVE_UNSAVED, item);
                    return data;
                } catch (error) {
                    handleError(commit, error);
                } finally {
                    commit(ACTIONS.TOGGLE_LOADING);
                }
            }
        }
    };
}
