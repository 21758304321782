export default function ({ app }) {
    app.i18n.onLanguageSwitched = (_, newLocale) => {
        if (app.$auth.loggedIn) {
            // TODO: We clone the user here to avoid an error with Vuex.
            //       To improve the situation we need to add a user model with a mutation to
            //       update the language.
            const user = Object.assign({}, app.$auth.user);
            user.language = newLocale;
            app.store.$auth.setUser(user);
            app.store.dispatch('users/update', { id: user.id, user });
        }
    };
}
