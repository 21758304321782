
import { mapActions } from 'vuex';
import IconList from '~/components/IconList';

export default {
  name: 'UserMenu',
  components: {
    IconList
  },
  data () {
    return {
      items: [
        {
          title: 'general.profile',
          dataTest: 'profile',
          icon: 'mdi-account-edit',
          to: '/users/edit',
          query: { id: this.$auth.user.id }
        },
        {
          title: 'general.changelog',
          dataTest: 'link-changelog',
          icon: 'mdi-autorenew',
          click: () => window.open('https://confluence.vnr.de/x/QL8LBQ'),
        },
        {
          title: 'auth.logout',
          dataTest: 'logout',
          icon: 'mdi-logout',
          click: () => this.logout()
        }
      ]
    };
  },
  computed: {

  },
  methods: {
    ...mapActions(['clearAll']),
    logout () {
      this.clearAll();
      this.$auth.logout().then(() => {
        this.$router.replace(this.localePath('/auth/login'));
      });
    }
  }
};
