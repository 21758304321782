'use strict';

import { Credential } from '../types';
import DataOptions from '~/models/vuetify/DataOptions';

export const state = () => ({
    items: [] as Array<Credential>,
    search: {},
    loading: false,
    serverItemsLength: 0,
    options: new DataOptions({
        itemsPerPage: 10,
        sortBy: ['updated_at'],
        sortDesc: [true]
    })
});

export type CredentialsModuleState = ReturnType<typeof state>;

export default state;
