export enum StorageKind {
    // eslint-disable-next-line no-unused-vars
    session = 'sessionStorage',
    // eslint-disable-next-line no-unused-vars
    local = 'localStorage'
}

export interface StorageGetArgs {
    kind: StorageKind.session | StorageKind.local;
    key: string;
}

export interface StorageSetArgs extends StorageGetArgs {
    value: any;
}

export interface StorageInterface {
    set: (args: StorageSetArgs) => void;
    get: (args: StorageGetArgs) => string | undefined;
    remove: (args: StorageGetArgs) => void;
}
