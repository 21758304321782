/**
 * Auto-generated
 * @see \Database\Seeders\RolesAndPermissionsSeeder::generateConstFiles()
 */

export default (_, inject) => {
    inject('permission', {
        add_user: 'add user',
        show_user: 'show user',
        showall_user: 'showAll user',
        edit_user: 'edit user',
        delete_user: 'delete user',
        edit_roles_of_user: 'edit roles of user',
        add_tenant: 'add tenant',
        show_tenant: 'show tenant',
        showall_tenant: 'showAll tenant',
        edit_tenant: 'edit tenant',
        delete_tenant: 'delete tenant',
        edit_users_of_tenant: 'edit users of tenant',
        add_landingpage: 'add landingpage',
        show_landingpage: 'show landingpage',
        showall_landingpage: 'showAll landingpage',
        edit_landingpage: 'edit landingpage',
        delete_landingpage: 'delete landingpage'
    });
};
