/**
 * @see https://vuetifyjs.com/en/api/v-data-table/#props-options
 */
export default class DataOptions {
    page = 1;
    itemsPerPage = 10;
    sortBy = [];
    sortDesc = [];
    groupBy = [];
    groupDesc = [];
    multiSort = true;
    mustSort = false;

    constructor (config = {}) {
        this.page = config.page;
        this.itemsPerPage = config.itemsPerPage;
        this.sortBy = config.sortBy;
        this.sortDesc = config.sortDesc;
        this.groupBy = config.groupBy;
        this.groupDesc = config.groupDesc;
        this.multiSort = config.multiSort;
        this.mustSort = config.mustSort;
    }
}
