import { GetterTree, ActionTree, MutationTree } from 'vuex';

export const state = () => ({});

// eslint-disable-next-line
export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {};

export const actions: ActionTree<RootState, RootState> = {
    clearAll ({ commit }) {
        commit('tenant/reset');
        commit('stores/reset');
        commit('campaigns/reset');
        commit('tenants/reset');
        commit('roles/reset');
        commit('landingpage/reset');
        commit('lp_hints/reset');
    },
};
