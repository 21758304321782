import { getField, updateField } from 'vuex-map-fields';

function initialState () {
    return {
        isLoading: false,
        error: null,
    };
}

export default {

    state: initialState,

    getters: {
        getField,
    },

    mutations: {
        updateField,

        startLoading (state) {
            state.isLoading = true;
        },
        stopLoading (state) {
            state.isLoading = false;
        },
        setError (state, error) {
            state.error = error;
        }
    },

    actions: {
        activate: async ({ commit }, { revisionId, landingPageId }) => {
            commit('startLoading');
            try {
                await global.$nuxt.$axios.post('/landingpages/' + landingPageId + '/revisions/' + revisionId + '/activate');
                return true;
            } catch (error) {
                commit('setError', error.response.data.errors.landingpage[0]);
            } finally {
                commit('stopLoading');
            }
        },
    }
};
