/**
 * Represents a full landing page object that should be a reflection of the apis model of the object.
 * Make sure that when you make changes to the landing page model in the backend to reflect
 * that changes here.
 */
export default class LandingPage {
    constructor (args) {
        if (!args) {
            return new LandingPage({});
        }
        this.id = args.id || null;
        this.title = args.title || '';
        this.store_id = args.store_id || null;
        this.product_id = args.product_id || null;
        this.campaign_id = args.campaign_id || null;
        this.campaign_ids = args.campaign_ids || null;
        this.deployment_credential_id = args.deployment_credential_id || null;
        this.deployment_target_url = args.deployment_target_url || null;
        this.type = args.type;
        this.code = args.code || '';
        this.url = args.url || '';
        this.slug = args.slug || '';
        this.user = args.user || null;
        this.user_id = args.user_id || null;
        this.username = args.username || '';
        this.product_title = args.product_title || '';
        this.product_shorthand = args.product_shorthand ?? '';
        this.activity = args.activity || null;
        this.revisions = args.revisions || [];
        this.workflow_type = args.workflow_type || null;
        this.status = args.status || 0;
        this.deactivated_at = args.deactivated_at || null;
        this.updated_at = args.updated_at || null;
        this.redirect_url = args.redirect_url || null;
        this.form_skin = args.form_skin || null;
        this.assets = args.assets || [];
        this.snippets = args.snippets || [];
        this.is_brizy = args.is_brizy;
        this.eTermin_url = args.eTermin_url;
    }

    /**
     * Determines whether the landing page has been deactivated.
     *
     * @returns {boolean}
     */
    isDeactivated () {
        return this.deactivated_at !== null;
    }
}
