'use strict';

import { ActionTree } from 'vuex/types/index';
import { Context } from '@nuxt/types';

import { RootState } from '~/store';

import {
    fetchUser,
    fetchUsers,
    createUser,
    fetchRoles,
    deleteUser,
    restoreUser,
    updateUser
} from '~/modules/users/api';
// TODO: Find out why relative imports are not working
import { getters } from '~/modules/users/store/getters';
import { state, UsersModuleState } from '~/modules/users/store/state';
import { mutations, MUTATIONS } from '~/modules/users/store/mutations';

export default ({ store, app }: Context) => {
    const actions: ActionTree<UsersModuleState, RootState> = {
        startLoading: ({ commit }) => commit(MUTATIONS.START_LOADING),
        stopLoading: ({ commit }) => commit(MUTATIONS.STOP_LOADING),
        setTableOptions: ({ commit }, options) => {
            commit(MUTATIONS.SET_TABLE_OPTIONS, options);
        },
        localUpdate: ({ commit }, data) => {
            commit(MUTATIONS.ADD_OR_UPDATE, data);
        },
        // This could need some caching mechanism to reduce api requests.
        fetchAll: async (
            { commit, dispatch, state },
            { paginated = true } = {}
        ) => {
            try {
                commit(MUTATIONS.START_LOADING);

                const { data } = await fetchUsers(
                    paginated ? state.tableOptions : null
                );

                commit(MUTATIONS.SET_LIST, data.data);
                commit(MUTATIONS.SET_TOTAL, data.meta.total);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        fetchRoles: async ({ commit, dispatch }, params) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await fetchRoles({ params });
                commit(MUTATIONS.SET_ROLES, data.data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        create: async ({ commit, dispatch }, user) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await createUser(user);
                commit(MUTATIONS.ADD_OR_UPDATE, data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },

        fetch: async ({ commit, dispatch }, id) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await fetchUser(id);
                commit(MUTATIONS.ADD_OR_UPDATE, data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        update: async ({ commit, dispatch }, { id, user }) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await updateUser(id, user);
                commit(MUTATIONS.ADD_OR_UPDATE, data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        delete: async ({ commit, dispatch }, id) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await deleteUser(id);
                commit(MUTATIONS.ADD_OR_UPDATE, data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        restore: async ({ commit, dispatch }, id) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await restoreUser(id);
                commit(MUTATIONS.ADD_OR_UPDATE, data);
                return data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },

        apiError: (_, error) => {
            if (error && error.response && error.response.data) {
                if (error.response.data.message) {
                    app.$toast.error(error.response.data.message);
                }
            }
        },
        fetchAllWithLandingPageRelation: async ({ dispatch, commit }) => {
            try {
                commit(MUTATIONS.START_LOADING);
                const { data } = await fetchUsers({
                    params: { hasLandingPages: true }
                });
                return data.data;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        }
    };

    store.registerModule('users', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
