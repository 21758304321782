import LpHint from '~/models/LpHint';

function initialState () {
    return {
        hints: []
    };
}

const validationHintKeys = [
    'missing_placeholders',
    'missing_html_tags',
    'too_many_placeholders',
    'invalid_placeholder_attribute_campaign_missing',
    'invalid_placeholder_attribute_campaign_invalid',
    'invalid_placeholder_attribute_id_missing',
    'invalid_placeholder_attribute_id_invalid',
    'forbidden_snippets',
];

export default {
    state: initialState,

    getters: {
        getHints: state => (lpId) => {
            return state.hints.filter((hint) => {
                return hint.lpId === lpId;
            });
        },
        getByLevel: (_, getters) => (lpId, level) => {
            return getters.getHints(lpId).filter((hint) => {
                return hint.level === level;
            });
        },
        count: state => (lpId) => {
            return state.hints.filter((hint) => {
                return hint.lpId === lpId;
            }).length;
        }
    },

    mutations: {
        addHint (state, hint) {
            state.hints.push(hint);
        },
        removeHint (state, hintId) {
            const hintIndex = state.hints.findIndex(hint => hint.id === hintId);
            if (hintIndex >= 0) {
                state.hints.splice(hintIndex, 1);
            }
        },
        reset (state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });
        }
    },

    actions: {
        add ({ commit }, hint) {
            if (!(hint instanceof LpHint)) {
                throw new TypeError('Please use an LpHint model as parameter');
            }

            commit('removeHint', hint.id);
            commit('addHint', hint);
        },
        remove ({ commit }, hintId) {
            commit('removeHint', hintId);
        },
        resetValidation ({ commit }) {
            validationHintKeys.forEach((key) => {
                commit('removeHint', key);
            });
        },
    }
};
