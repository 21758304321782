const ENDPOINT = '/users';

export const fetchUsers = params =>
    global.$nuxt.$axios({
        method: 'get',
        url: ENDPOINT,
        params
    });

export const createUser = data =>
    global.$nuxt.$axios({
        method: 'post',
        url: ENDPOINT,
        data
    });

export const fetchUser = id =>
    global.$nuxt.$axios({
        method: 'get',
        url: `${ENDPOINT}/${id}`
    });

export const updateUser = (id, data) =>
    global.$nuxt.$axios({
        method: 'put',
        url: `${ENDPOINT}/${id}`,
        data
    });

export const deleteUser = id =>
    global.$nuxt.$axios({
        method: 'delete',
        url: `${ENDPOINT}/${id}`
    });

export const restoreUser = id =>
    global.$nuxt.$axios({
        method: 'put',
        url: `${ENDPOINT}/${id}/restore`
    });

export const fetchRoles = params =>
    global.$nuxt.$axios({
        method: 'get',
        url: 'roles',
        params
    });

export const api = {
    list: fetchUser,
    create: createUser,
    get: fetchUser,
    update: updateUser,
    delete: deleteUser,
    restore: restoreUser,
    listRoles: fetchRoles
};

export default api;
