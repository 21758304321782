import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23ce9522 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3b7ada26 = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _06780416 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _48426e4a = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _67ce8f24 = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _75e92240 = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _0416b7de = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _6f97586c = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _74f3690c = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _ef3cfedc = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _2ca71896 = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _73964da9 = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _51eef1a7 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _3a86ee3e = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _248cbe15 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _52c5e358 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _66e2c47a = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _ab71bed6 = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _fd7d6d82 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _73594592 = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _55137733 = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _5cf46e3c = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _6aacfe76 = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _195d9458 = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _53507222 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _26c2e2f8 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _1193d12e = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _fbd76c3e = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _23ce9522,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _3b7ada26,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _06780416,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _48426e4a,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _67ce8f24,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _3b7ada26,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _06780416,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _48426e4a,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _67ce8f24,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _23ce9522,
    name: "index___de"
  }, {
    path: "/users",
    component: _75e92240,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _75e92240,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _0416b7de,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _0416b7de,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _6f97586c,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _6f97586c,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _74f3690c,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _74f3690c,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _ef3cfedc,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _ef3cfedc,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _2ca71896,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _2ca71896,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _73964da9,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _73964da9,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _51eef1a7,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _51eef1a7,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _3a86ee3e,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _3a86ee3e,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _248cbe15,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _248cbe15,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _52c5e358,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _52c5e358,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _66e2c47a,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _66e2c47a,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _ab71bed6,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _ab71bed6,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _fd7d6d82,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _fd7d6d82,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _73594592,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _73594592,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _55137733,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _55137733,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _5cf46e3c,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _5cf46e3c,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _6aacfe76,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _6aacfe76,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _195d9458,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _195d9458,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _53507222,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _53507222,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _26c2e2f8,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _26c2e2f8,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _1193d12e,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _1193d12e,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _fbd76c3e,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _fbd76c3e,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
