
// Wraps https://vuetifyjs.com/en/components/menus/
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  }
};
