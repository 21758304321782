import { getField, updateField } from 'vuex-map-fields';

function initialState () {
    return {
        isLoading: false,
        deployment_error: null,
    };
}

export default {

    state: initialState,

    getters: {
        getField,
    },

    mutations: {
        updateField,

        startLoading (state) {
            state.isLoading = true;
        },
        stopLoading (state) {
            state.isLoading = false;
        },
        setError (state, error) {
            state.deployment_error = error;
        }
    },

    actions: {
        async dispatch ({ commit }, payload) {
            commit('startLoading');

            try {
                await this.$axios.post('/deploy', {
                    landingpage: payload.id
                });
                return true;
            } catch (error) {
                commit('setError', error.response.data.message);
                return false;
            } finally {
                commit('stopLoading');
            }
        },
    }
};
