import { User, UserRole } from '../types';

export const state = () => ({
    total: 0,
    loading: 0,
    list: [] as User[],
    roles: [] as UserRole[],
    tableOptions: {
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true,
        itemsPerPage: 10
    }
});

export type UsersModuleState = ReturnType<typeof state>;

export default state;
