export default function ({ $axios, app }) {
    $axios.onError((error) => {
        if (error.response.status === 401) {
            app.$auth.logout();
        }
    });

    $axios.onRequest(() => {
        if (app.i18n) {
            $axios.setHeader('x-locale', app.i18n.locale);
        }
    });
}
