import {
    StorageInterface,
    StorageSetArgs,
    StorageGetArgs
} from '~/modules/storage/types';

class Storage implements StorageInterface {
    set (args: StorageSetArgs) {
        if (this.testAvailability(args.kind)) {
            const storage = (<any>window)[args.kind];
            storage.setItem(args.key, JSON.stringify(args.value));
        }
    }

    get (args: StorageGetArgs) {
        if (this.testAvailability(args.kind)) {
            const storage = (<any>window)[args.kind];
            const items = storage.getItem(args.key);
            return items ? JSON.parse(items) : null;
        }
    }

    remove (args: StorageGetArgs) {
        if (this.testAvailability(args.kind)) {
            const storage = (<any>window)[args.kind];
            storage.removeItem(args.key);
        }
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
     */
    testAvailability (kind: string): boolean {
        let storage;
        try {
            storage = (<any>window)[kind];
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`No ${kind} available`);
            return false;
        }
    }
}

export default new Storage();
