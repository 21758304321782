/**
 * When updating the roles:
 * Update also backend enum Domains/UserManagement/Enums/RoleEnum.php.
 */
export default (_, inject) => {
    inject('role', {
        super_admin: 'Super-Admin',
        admin: 'Admin',
        produktmanager: 'Produktmanager',
        html_setzer: 'HTML-Setzer',
        marketingspezialist: 'Marketingspezialist'
    });
};
