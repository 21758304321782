
import has from 'lodash/has';

export default {
  name: 'IconList',
  props: {
    dataTest: {
      type: String,
      required: true
    },
    mainIcon: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      validator: (values) => {
        let valid = true;
        values.forEach((item) => {
          if (!(
            has(item, 'title') &&
                        (has(item, 'to') || has(item, 'click'))
          )) {
            valid = false;
          }
          if (has(item, 'click') && {}.toString.call(item.click) !== '[object Function]') {
            valid = false;
          }
        });
        return valid;
      }
    }
  },
  methods: {
    itemHas (item, value) {
      return has(item, value);
    },
    getTo (item) {
      let to = this.localePath(item.to);
      if (this.itemHas(item, 'query')) {
        to += '?';
        for (const [key, value] of Object.entries(item.query)) {
          to += key + '=' + value + '&';
        }
        to = to.substr(0, to.length - 1);
      }
      return to;
    }
  }
};
