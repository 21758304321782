import { GetterTree, ActionTree, MutationTree } from 'vuex/types/index';
import { Context } from '@nuxt/types';
import Vue from 'vue';

import { Product, ProductType } from '~/modules/products/types';
import { fetchProducts } from '~/modules/products/api';
import { RootState } from '~/store';

export const state = () => ({
    products: [],
    isLoading: false
});

export type ProductsModuleState = ReturnType<typeof state>;

export const MUTATIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    SET_PRODUCTS: 'SET_PRODUCTS'
};

export default ({ store }: Context) => {
    const getters: GetterTree<ProductsModuleState, RootState> = {
        products: state => state.products
    };

    const mutations: MutationTree<ProductsModuleState> = {
        [MUTATIONS.START_LOADING]: (state) => {
            Vue.set(state, 'isLoading', true);
        },
        [MUTATIONS.STOP_LOADING]: (state) => {
            Vue.set(state, 'isLoading', false);
        },
        [MUTATIONS.SET_PRODUCTS]: (state, products) => {
            Vue.set(state, 'products', products);
        }
    };

    const actions: ActionTree<ProductsModuleState, RootState> = {
        refreshProducts: async ({ commit }, params) => {
            commit(MUTATIONS.START_LOADING);
            try {
                const { data }: { data: ProductType[] } = await fetchProducts(
                    params
                );
                commit(
                    MUTATIONS.SET_PRODUCTS,
                    data.map((product: ProductType) => new Product(product))
                );
            } catch (error) {
                //
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        }
    };

    store.registerModule('products', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
