export function handleError (error) {
    if (!error.response) { global.$nuxt.$toast.error(error); }

    if (!error.response.data) {
        global.$nuxt.$toast.error(global.$nuxt.$t('general.unexpected_error_exception').toString());
        return Promise.reject(error);
    }
    if (typeof error.response.data === 'string') {
        global.$nuxt.$toast.error(error.response.data);
        return Promise.reject(error);
    }

    if (error.response.data.message) {
        let message = error.response.data.message;
        if (error.response.data.message.search('No query result for model')) {
            message = 'No data found';
        }

        global.$nuxt.$toast.error(message);
    }

    return Promise.reject(error);
}

export default function makeService (endpoint) {
    return {
        find (id) {
            return global.$nuxt.$axios.get(endpoint + '/' + id);
        },
        findAll (params) {
            return global.$nuxt.$axios.get(endpoint, {
                params
            });
        },
        create (payload) {
            return global.$nuxt.$axios.post(endpoint, payload);
        },
        delete (item) {
            return global.$nuxt.$axios.delete(endpoint + '/' + item.id);
        },
        update (payload) {
            return global.$nuxt.$axios.put(endpoint + '/' + payload.id, payload);
        }
    };
}
