import { MUTATIONS } from './mutations';

const API_ENDPOINT = '/deployment_credentials';

export default {
    apiError: ({ commit }, error) => {
        if (error && error.response && error.response.data) {
            if (error.response.data.message) {
                global.$nuxt.$toast.error(error.response.data.message);
            }
            if (error.response.data.errors) {
                commit(MUTATIONS.SET_ERRORS, error.response.data.errors);
            }
        }
    },
    /************************************************************************************************
     * API Requests                                                                                 *
     ************************************************************************************************/
    list: async ({ commit, dispatch }, params) => {
        commit(MUTATIONS.START_LOADING);
        commit(MUTATIONS.RESET_ERRORS);

        try {
            const { data } = await global.$nuxt.$axios.get(API_ENDPOINT, {
                params
            });
            commit('SET_LIST', data.data);
        } catch (error) {
            dispatch('apiError', error);
        } finally {
            commit(MUTATIONS.STOP_LOADING);
        }
    },
};
