const ENDPOINT = '/products';

export const fetchProducts = (params: any) =>
    window.$nuxt.$axios({
        method: 'get',
        url: ENDPOINT,
        params
    });

export default {
    list: fetchProducts,
};
