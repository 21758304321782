

export default {
  name: 'LoadingOverlay',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    opacity: {
      type: Number,
      required: false,
      default: 0.9,
    },
    zIndex: {
      type: Number,
      required: false,
      default: 100,
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
