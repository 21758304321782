import Vue from 'vue';
import { MutationTree } from 'vuex/types/index';
import { LandingPageTableOptions } from '../types';

import { LandingPagesModuleState } from './state';

export const MUTATIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    ADD_LP: 'ADD_LP',
    REMOVE_LP: 'REMOVE_LP',
    ADD_ID: 'ADD_ID',
    REMOVE_ID: 'REMOVE_ID',
    SELECT: 'SELECT',
    UNSELECT: 'UNSELECT',
    ADD_UNSAVED: 'ADD_UNSAVED',
    REMOVE_UNSAVED: 'REMOVE_UNSAVED',
    SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
    RESET_LIST: 'RESET_LIST',
    SET_ERRORS: 'SET_ERRORS',
    RESET_ERRORS: 'RESET_ERRORS',
    RESET_FORM_VALIDATIONS: 'RESET_FORM_VALIDATIONS',
    ADD_FORM_VALIDATION: 'ADD_FORM_VALIDATION',
    SET_CONFIRMATION_REQUIRED: 'SET_CONFIRMATION_REQUIRED',
    REMOVE_ERROR: 'REMOVE_ERROR',
    SET_TABLE_OPTIONS: 'SET_TABLE_OPTIONS'
};

export const mutations: MutationTree<LandingPagesModuleState> = {
    [MUTATIONS.START_LOADING]: (state) => {
        Vue.set(state, 'isLoading', true);
    },
    [MUTATIONS.STOP_LOADING]: (state) => {
        Vue.set(state, 'isLoading', false);
    },
    [MUTATIONS.ADD_LP]: (state, item) => {
        Vue.set(state.list, item.id, item);
    },
    [MUTATIONS.REMOVE_LP]: (state, id) => {
        const ids = [...state.ids.filter(x => x !== id)];
        Vue.set(state, 'ids', ids);

        const list = { ...state.list };
        delete list[id];
        Vue.set(state, 'list', list);
    },
    [MUTATIONS.ADD_ID]: (state, id) => {
        if (!state.ids.includes(id)) {
            const ids = [...state.ids];
            ids.push(id);
            Vue.set(state, 'ids', ids);
        }
    },
    [MUTATIONS.REMOVE_ID]: (state, id) => {
        if (state.unsaved.includes(id)) {
            const ids = [...state.ids.filter(item => item !== id)];
            Vue.set(state, 'ids', ids);
        }
    },
    [MUTATIONS.ADD_UNSAVED]: (state, id) => {
        if (!state.unsaved.includes(id)) {
            const unsaved = [...state.unsaved];
            unsaved.push(id);
            Vue.set(state, 'unsaved', unsaved);
        }
    },
    [MUTATIONS.REMOVE_UNSAVED]: (state, id) => {
        if (state.unsaved.includes(id)) {
            const unsaved = [...state.unsaved.filter(i => i !== id)];
            Vue.set(state, 'unsaved', unsaved);
        }
    },
    [MUTATIONS.SET_TOTAL_ITEMS]: (state, totalItems) => {
        Vue.set(state, 'totalItems', totalItems);
    },
    [MUTATIONS.RESET_LIST]: (state) => {
        state.ids = [];
        state.list = {};
        state.unsaved = [];
        state.isLoading = false;
    },
    [MUTATIONS.SET_ERRORS]: (state, errors) => {
        state.errors = errors;
    },
    [MUTATIONS.RESET_ERRORS]: (state) => {
        state.errors = {};
    },
    [MUTATIONS.RESET_FORM_VALIDATIONS]: (state) => {
        Vue.set(state, 'formValidations', {});
    },
    [MUTATIONS.ADD_FORM_VALIDATION]: (state, { formName, isValid }) => {
        Vue.set(state.formValidations, formName, isValid);
    },
    [MUTATIONS.SET_CONFIRMATION_REQUIRED]: (state, isRequired) => {
        Vue.set(state, 'requiresConfirmation', isRequired);
    },
    [MUTATIONS.SELECT]: (state, id) => {
        Vue.set(state, 'selected', id);
    },
    [MUTATIONS.UNSELECT]: (state) => {
        Vue.set(state, 'selected', null);
    },
    [MUTATIONS.REMOVE_ERROR]: (state, key) => {
        Vue.set(state.errors, key, '');
    },
    [MUTATIONS.SET_TABLE_OPTIONS]: (
        state,
        tableOptions: LandingPageTableOptions
    ) => {
        Vue.set(state, 'tableOptions', tableOptions);
    }
};

export default mutations;
