
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ChangeTenant',
  computed: {
    ...mapGetters('tenant', {
      localTenant: 'getTenant'
    })
  },
  methods: {
    ...mapActions('tenant', {
      setTenant: 'set'
    })
  }
};
