import { GetterTree } from 'vuex/types/index';
import { isEqual } from 'lodash';

import { LandingPageTableOptions } from '../types';
import { RootState } from '~/store';

import LandingPage from '~/models/LandingPage';

import {
    LandingPagesModuleState,
    defaultTableOptions
} from '~/modules/landingPages/store/state';

export const getters: GetterTree<LandingPagesModuleState, RootState> = {
    list: (state, getters) => state.ids.map(id => getters.show(id)),
    show:
        state =>
            (id: string | number): LandingPage => {
                return state.list[id];
            },
    selected: (state, getters): LandingPage | null => {
        if (!state.selected) {
            return null;
        }
        return getters.show(state.selected);
    },
    code: (_, getters) => {
        return getters.selected !== null ? getters.selected.code : '';
    },
    isUnsaved: state => (id: string | number) => {
        return state.unsaved.includes(id);
    },
    isSelectedUnsaved: (state, getters) => {
        if (!state.selected) {
            return false;
        }
        return getters.isUnsaved(state.selected);
    },
    isValid: state =>
        Object.keys(state.formValidations).filter(
            key => !state.formValidations[key]
        ).length === 0,
    isLoading: state => state.isLoading,
    requiresConfirmation: state => state.requiresConfirmation,
    listHasFilters: state =>
        !isEqual(
            cleanUpTableOptions(state.tableOptions),
            cleanUpTableOptions(defaultTableOptions)
        )
};

// Removes keys with empty values from the tableOptions object
function cleanUpTableOptions (options: LandingPageTableOptions) {
    return Object.fromEntries(Object.entries(options).filter(([_, v]) => !!v));
}

export default getters;
