/* eslint-disable camelcase */
export type ProductType = {
    id: number;
    uid: number;
    campaign_id: number;
    shorthand: string;
    title: string;
    wkz: string;
};

export class Product {
    readonly id: number;
    readonly uid: number;
    readonly campaign_id: number;
    readonly shorthand: string;
    readonly title: string;
    readonly wkz?: string;

    constructor ({ id, uid, campaign_id, shorthand, title, wkz }: ProductType) {
        this.id = id;
        this.uid = uid;
        this.campaign_id = campaign_id;
        this.shorthand = shorthand;
        this.title = title;
        this.wkz = wkz;
    }

    public getDisplayName (): string {
        return `${this.shorthand}: ${this.title}`;
    }
}
