import makeCrudModule, { ACTIONS } from '@/factories/CrudModelFactory';
import tenantsService from '@/services/tenants';

const crudModule = makeCrudModule({
    service: tenantsService,
    dateConvert: [
        'created_at', 'updated_at'
    ]
});

export default {
    state: () => ({
        ...crudModule.state()
    }),
    getters: {
        ...crudModule.getters
    },
    mutations: {
        ...crudModule.mutations
    },
    actions: {
        ...crudModule.actions,
        get: async ({ commit }, id) => {
            commit(ACTIONS.TOGGLE_LOADING);
            commit(ACTIONS.RESET_ERRORS);
            try {
                const { data } = await global.$nuxt.$axios.get(`tenants/${id}`);
                commit(ACTIONS.ADD, data);
            // eslint-disable-next-line no-useless-catch
            } catch (error) {
                throw error;
            } finally {
                commit(ACTIONS.TOGGLE_LOADING);
            }
        },
    }
};
