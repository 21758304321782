

export default {
  data () {
    return {
      items: [
        {
          short: 'de',
          label: 'general.language.de',
          img: '/de.png',
        },
        {
          short: 'en',
          label: 'general.language.en',
          img: '/en.png',
        },
      ]
    };
  },
};
