import Vue from 'vue';

export const MUTATIONS = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    SET_LIST: 'SET_LIST',
    RESET_LIST: 'RESET_LIST',
    SET_ERRORS: 'SET_ERRORS',
    RESET_ERRORS: 'RESET_ERRORS',
};

export default {
    [MUTATIONS.START_LOADING]: (state) => {
        Vue.set(state, 'isLoading', true);
    },
    [MUTATIONS.STOP_LOADING]: (state) => {
        Vue.set(state, 'isLoading', false);
    },
    [MUTATIONS.SET_LIST]: (state, list) => {
        Vue.set(state, 'list', list);
    },
    [MUTATIONS.RESET_LIST]: (state) => {
        Vue.set(state, 'list', []);
    },
    [MUTATIONS.SET_ERRORS]: (state, errors) => {
        state.errors = errors;
    },
    [MUTATIONS.RESET_ERRORS]: (state) => {
        state.errors = {};
    },
};
