
import { mapFields } from 'vuex-map-fields';
import LoadingOverlay from '~/components/System/LoadingOverlay.vue';
import loadTenant from '~/mixins/loadTenant';

export default {
  name: 'DefaultLayout',
  components: {
    LoadingOverlay,
  },
  mixins: [loadTenant],
  middleware: 'authenticated',
  computed: {
    ...mapFields('tenant', {
      loadTenantChange: 'isLoading'
    })
  }
};
