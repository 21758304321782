import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const getReleaseStageByApiUrl = (apiUrl: string): string => {
    switch (apiUrl) {
    case 'https://api.lpm.pl-x.de':
        return 'production';
    case 'https://api.staging.lpm.pl-x.de':
        return 'staging';
    case 'https://api.test.lpm.pl-x.de':
        return 'test';
    default:
        return 'local';
    }
};

const bugsnagPlugin: Plugin = ($context) => {
    if (!$context.env.BUGSNAG_API_KEY || $context.isDev) {
        return;
    }

    Bugsnag.start({
        apiKey: $context.env.BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        releaseStage: getReleaseStageByApiUrl($context.env.API_URL as string)
    });

    const bugsnagVue = Bugsnag.getPlugin('vue')!;
    bugsnagVue.installVueErrorHandler(Vue);
};

export default bugsnagPlugin;
