import { getField } from 'vuex-map-fields';

function initialState () {
    return {
        id: null,
        name: '',
        database: '',
        isLoading: false
    };
}

export default {

    state: initialState,

    getters: {
        getField,
        getTenant: (state) => {
            return {
                id: state.id,
                name: state.name,
                database: state.database,
                isLoading: false
            };
        }
    },

    mutations: {
        setTenant (state, tenant) {
            state.id = tenant.id;
            state.database = tenant.database;
            state.name = tenant.name;
        },
        reset (state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });
        },
        toggleLoading (state) {
            state.isLoading = !state.isLoading;
        }
    },

    actions: {
        load ({ commit }) {
            this.$axios.get('/tenant').then((response) => {
                commit('setTenant', response.data.data);
            });
        },
        set ({ commit }, tenant) {
            commit('toggleLoading');
            this.$axios.post('/tenant', tenant.id)
                .then((response) => {
                    commit('setTenant', response.data);
                    location.reload();
                }
                ).catch(() => {
                    this.$toast.error(this.$t('tenant.switch_tenant_exception').toString());
                });
        }
    }
};
